<template>
  <div class="cooperationPage">
    <!-- <headerComponent /> -->

    <img :src="require('@/assets/bgc4.jpg')" alt="" class="banner" />

    <div class="pubCon">
      <div class="contactCon">
        <div class="lfInfo" :class="isTransform ? 'left' : ''">
          <!-- <div class="npx">
            <img :src="require('assets/images/yz.png')" alt="" />

            微信客服企业号
          </div> -->

          <h1>深圳写手智能科技有限公司</h1>
          <h2>XIE SHOU ZHI NENG KE JI YOU XIAN GONG SI</h2>
          <div class="line"></div>
          <p>
            <a href="###"
              ><i class="el-icon-office-building"></i><span>颜先生</span></a
            >
          </p>

          <!-- <p>
            <a href="tel:4008256828"
              ><i class="el-icon-phone"></i><span>400-825-6828</span></a
            >
          </p>
          <p>
            <a href="tel:13828584514"
              ><i class="el-icon-phone"></i><span>13828584514</span></a
            >
          </p> -->
          <p>
            <a href="###"
              ><i class="el-icon-message"></i><span>writemall@126.com</span></a
            >
          </p>
          <p>
            <a href="###"
              ><i class="el-icon-location"></i
              ><span>深圳市宝安区新安三路嘉盛智丰荟产业园1栋219</span></a
            >
          </p>
        </div>
        <div class="rtForm" :class="isTransform ? 'right' : ''">
          <p class="title">填写信息,咨询与合作</p>

          <el-input placeholder="电话" v-model="phone" clearable> </el-input>

          <br />
          <br />
          <el-input
            placeholder="留言内容"
            v-model="content"
            clearable
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
          >
          </el-input>
          <br />
          <br />
          <el-input placeholder="邮箱" v-model="mailbox" clearable> </el-input>
          <br />

          <div class="btn" @click="tijiao">提交</div>
        </div>
      </div>

      <baidu-map
        :center="{ lng: 113.91717, lat: 22.584223 }"
        zoom="17"
        @ready="map_handler"
        style="height: 400px; margin: 20px 0 0"
      ></baidu-map>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import headerComponent from "./headerComponent";
import { addContactUs } from "api/introduce";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "cooperationPage",
  //import引入的组件需要注入到对象中才能使用
  components: {
    // headerComponent,
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      phone: "",
      mailbox: "",
      content: "",
      isTransform: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    map_handler({ BMap, map }) {
      var marker = new BMap.Marker(new BMap.Point(113.91717, 22.584223));
      map.addOverlay(marker);
    },

    async tijiao() {
      const data = {
        type: 1,
        name: "合作咨询",
        mobile: this.phone,
        platform: 3,
        content: this.content,
        mailbox: this.mailbox,
        pictures: [],
      };

      const res = await addContactUs(data);

      if (res.code == 200) {
        (this.phone = ""),
          (this.mailbox = ""),
          (this.content = ""),
          this.$message({
            type: "success",
            message: "提交成功",
          });
      } else {
        this.$message(res.message);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.isTransform = true;
  },
};
</script>
<style lang="scss" scoped>
.cooperationPage {
  position: absolute;
  background: #f5f5f2;
  top: 0px;
  left: 0;
  width: 100%;
  .pubCon {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    transform: translateY(-100px);
  }

  .banner {
    width: 100%;
    height: 420px;
  }
  .contactCon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .contactCon .lfInfo {
    width: 50%;
    background-color: #4587ff;
    color: #f5f5f5;
    padding: 70px 70px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 10px 1px #6d6565;
    box-shadow: 0px 0px 10px 1px #6d6565;
    transition: 1.3s;
    opacity: 0;
    transform: translateX(-60px);
    position: relative;

    .npx {
      position: absolute;
      bottom: 75px;
      right: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 100px;

        margin-bottom: 10px;
      }
    }
  }
  .contactCon .lfInfo h1 {
    font-size: 24px;
    font-weight: 400;
  }
  .contactCon .lfInfo h2 {
    font-weight: 400;
    padding: 5px 0;
  }
  .contactCon .lfInfo .line {
    width: 50px;
    height: 1px;
    background-color: #e3ad49;
    margin: 20px 0 35px;
  }
  .contactCon .lfInfo a {
    color: #f5f5f5;
    font-size: 16px;
    display: block;
    padding: 5px 0;
    text-decoration: none;
  }
  .contactCon .lfInfo i {
    font-size: 1em;
    width: 30px;
    text-align: center;
  }
  .contactCon .lfInfo a span {
    padding-left: 10px;
  }
  .contactCon .rtForm {
    width: 50%;
    box-sizing: border-box;
    background-color: #e3ad49;
    color: #f5f5f5;
    padding: 40px;
    -webkit-box-shadow: 0px 0px 10px 1px #6d6565;
    box-shadow: 0px 0px 10px 1px #6d6565;
    transition: 1.3s;
    opacity: 0;
    transform: translateX(60px);

    .btn {
      float: right;
      width: 70px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 20px;
      border: 1px solid #fff;
      cursor: pointer;
      border-radius: 5px;
    }

    .el-input {
      ::v-deep .el-input__inner {
        background-color: #f5f5f2;
        // color: #fff;
        // border-color: #fff !important;
        // &::-webkit-input-placeholder {
        //   color: #f5f5f5;
        // }
      }
    }

    .el-textarea {
      ::v-deep .el-textarea__inner {
        background-color: #f5f5f2;
        // color: #fff;
        // border-color: #fff !important;

        // &::-webkit-input-placeholder {
        //   color: #f5f5f5;
        // }
      }
    }
  }

  .left {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }

  .right {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
  .contactCon .rtForm .title {
    padding-bottom: 20px;
  }

  .mapCon {
    width: 100%;
    height: 300px;
  }

  /* 1200px*/
  @media (max-width: 1200px) {
    .pubCon {
      padding-top: 20px;
    }
    .contactCon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      width: 90%;
      position: relative;
      top: 0;
      margin: auto;
    }
    .contactCon .lfInfo {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      background-color: #444444;
      color: #f5f5f5;
      padding: 20px;
      -webkit-box-shadow: 0px 0px 10px 1px #6d6565;
      box-shadow: 0px 0px 10px 1px #6d6565;
    }
    .contactCon .lfInfo h1 {
      font-size: 20px;
      font-weight: 400;
    }
    /* .contactCon .lfInfo h2 {
    font-weight: 400;
    padding: 5px 0;
  } */
    .contactCon .lfInfo .line {
      width: 100%;
      height: 1px;
      background-color: #e3ad49;
      margin: 20px 0 35px;
    }
    .contactCon .rtForm {
      -webkit-box-flex: none;
      -ms-flex: none;
      flex: none;
      background-color: #e3ad49;
      color: #f5f5f5;
      padding: 40px 0px;
      -webkit-box-shadow: 0px 0px 10px 1px #6d6565;
      box-shadow: 0px 0px 10px 1px #6d6565;
    }
    .contactCon .rtForm .title {
      padding-bottom: 20px;
    }

    .mapCon {
      width: 90%;
      margin: auto;
      height: 300px;
    }
  }
}
</style>
